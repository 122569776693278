import axios from 'axios'

export default {
  getSettings ( params, callback ) {
    axios.get('/settings', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
}
