import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate'

Vue.use(VeeValidate)

const dictionary = {
  en: {
    messages:{
      required: () => 'This field is required'
    }
  },
};

Validator.localize(dictionary);
