<template>
  <div class="my-account">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-user-circle"></i> My Account</span>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <router-link :to="{ name: tab.name }" class="nav-link"
          exact-active-class="active">{{ tab.label }}</router-link>
      </li>
    </ul>

    <div class="p-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountIndex',
  data () {
    return {
      tabs: [
        { name: 'account', label: 'Edit profile' },
        { name: 'account-password', label: 'Change password' },
      ],
    }
  },
  mounted () {},
  methods: {
    init () {
    }
  }
}
</script>
