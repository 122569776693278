<template>
  <div class="card border-light">
    <div class="card-body">
      <div class="loader-overlay" v-if="state == 'logging'">
        <moon-loader :color="'#28a745'"></moon-loader>
      </div>

      <h5 class="card-title border-bottom pb-2">Login to your account</h5>

      <div class="alert alert-danger" v-if="state == 'login-failed'">
        {{ errorMsg }}
      </div>

      <form method="POST" @submit.prevent="doLogin">
        <div class="form-group">
          <label for="loginEmail">Email address</label>
          <input type="email" tabindex="1" name="email" id="loginEmail" ref="inputEmail" autofocus="on"
            :class="{'form-control': true, 'is-invalid': errors.has('email')}"
            v-validate.disable="{ required: true, email: true }"
            v-model="email">
          <div class="invalid-feedback">{{ errors.first('email') }}</div>
        </div>
        <div class="form-group">
          <router-link tabindex="4" class="float-right" :to="{name: 'forgot'}">Forgot password?</router-link>
          <label for="loginPassword">Password</label>
          <input type="password" tabindex="2" name="password" id="loginPassword"
            :class="{'form-control': true, 'is-invalid': errors.has('password')}"
            v-validate.disable="{ required: true }"
            v-model="password">
          <div class="invalid-feedback">{{ errors.first('email') }}</div>
        </div>
        <button class="btn btn-primary" tabindex="3">Login Now</button>
      </form>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader'
import ServiceAuth from '@/services/auth'
import ServiceSetting from '@/services/setting'

export default {
  name: 'AuthLogin',
  components: {
    MoonLoader,
  },
  data () {
    return {
      state: '',
      email: '',
      password: '',
      errorMsg: ''
    };
  },
  computed: {},
  mounted () {
  },
  methods: {
    doLogin () {
      let self = this;

      if (self.state == 'logging') {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            self.state = 'logging';

            ServiceAuth.login(self.email, self.password, resp => {
              if ( resp.status == 'SUCCESS' ) {
                self.$localStorage.set('access_token', resp.access_token);

                ServiceSetting.getSettings({}, settings => {
                  self.setSettings( settings )
                  self.$router.push({ name: 'dashboard' });
                });
              }
              else {
                self.state = 'login-failed';
                self.errorMsg = resp.error;
              }
            });
          }
        });
    }
  }
}
</script>
