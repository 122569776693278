<template>
  <div class="comp-dashboard">
    <h3>Dashboard</h3>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'DashboardIndex',
  data () {
    return {

    }
  },
  computed: {},
  mounted () {
    this.init();
  },
  methods: {
    init () {
      // let self = this;
    }
  }
}
</script>
