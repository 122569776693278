import axios from 'axios'

export default {
  login ( email, password, callback ) {
    axios.post('/login', { email, password })
    .then(resp => {
      callback( resp.data );
    })
    .catch(() => {})
  },
  logout ( callback ) {
    axios.post('/logout')
    .then(resp => {
      callback( resp.data );
    })
    .catch(() => {})
  },
  forgot ( email, callback ) {
    axios.post('/forgot', { email })
    .then(resp => {
      callback( resp.data );
    })
    .catch(() => {})
  },
}
