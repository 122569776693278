<template>
  <div id="app" :class="{'initiating': state == 'init'}">
    <template v-if="state == 'init'">
      <grid-loader :color="'#ffffff'"></grid-loader>
    </template>

    <template v-else-if="state == 'unauthorized'">
      <div class="auth">
        <div class="wrap">
          <h1 class="logo mb-4">{{ env.VUE_APP_NAME }}</h1>
          <router-view></router-view>
          <div class="copyright pt-5 text-center">
            &copy; Copyright <a href="https://www.lendly.com.au/" target="_blank">Lendly Ltd</a>.
            All rights reserved.
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <app-header></app-header>

      <div class="container-fluid">
        <div class="row">
          <app-sidebar></app-sidebar>
          <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4 pb-4 main-content">
            <router-view></router-view>
          </main>
        </div>
      </div>

      <vue-progress-bar></vue-progress-bar>
      <notifications position="top center" classes="mynoty"></notifications>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { indexOf } from 'lodash'
import GridLoader from 'vue-spinner/src/GridLoader'

import ServiceSetting from '@/services/setting'
import AppHeader from '@/components/global/AppHeader'
import AppSidebar from '@/components/global/AppSidebar'

export default {
  name: 'app',
  components: {
    GridLoader,
    AppHeader,
    AppSidebar,
  },
  data () {
    return {
      state: 'init',
      authRoutes: ['login', 'forgot'],
      env: process.env,
    }
  },
  watch: {
    '$route' ( newVal ) {
      let self = this;
      if ( indexOf(self.authRoutes, newVal.name) >= 0 ) {
        self.state = 'unauthorized';
      }
      else {
        self.state = 'initiated';
      }
    }
  },
  computed: mapState({
    isInitiating () {
      return this.state == 'init';
    }
  }),
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;

      if ( indexOf(self.authRoutes, self.$route.name) >= 0 ) {
        self.state = 'unauthorized';
      }
      else {
        self.state = 'init';
        ServiceSetting.getSettings({}, settings => {
          self.state = 'initiated';
          self.setSettings( settings );
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss'
</style>
