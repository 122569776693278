<template>
  <div class="comp-pages">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-file"></i> Pages</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageIndex',
  data () {
    return {

    }
  },
  watch: {},
  computed: {},
  created () {},
  mounted () {},
  methods: {
    init () {

    }
  }
}
</script>
