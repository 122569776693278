<template>
  <modal name="modal-add-user" height="auto" :width="560"
    :scrollable="true" :clickToClose="false" @closed="$emit('input', false)">
    <div class="p-4">
      <h5 class="border-bottom pb-2 mb-3"><strong>Add User</strong></h5>
      <template v-if="postData">
        <form method="post" action="" ref="form" @submit.prevent="doCreate">
          <div class="form-row">
            <div class="form-group col">
              <label for="first_name">First Name <span class="text-danger">*</span></label>
              <input type="text" name="first_name" id="first_name" ref="inputName"
                :class="{'form-control': true, 'is-invalid': errors.has('first_name')}"
                v-validate.disable="{ required: true }"
                v-model="postData.first_name">
              <div class="invalid-feedback">{{ errors.first('first_name') }}</div>
            </div>
            <div class="form-group col">
              <label for="last_name">Last Name <span class="text-danger">*</span></label>
              <input type="text" name="last_name" id="last_name"
                :class="{'form-control': true, 'is-invalid': errors.has('last_name')}"
                v-validate.disable="{ required: true }"
                v-model="postData.last_name">
              <div class="invalid-feedback">{{ errors.first('last_name') }}</div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <label for="user_type_id">User Type <span class="text-danger">*</span></label>
              <select name="user_type_id" id="user_type_id"
                :class="{'form-control': true, 'is-invalid': errors.has('user_type_id')}"
                v-validate.disable="{ required: true }"
                v-model="postData.user_type_id">
                <option value="">- Select user type</option>
                <option v-for="(userType, index) in userTypes" :key="index" :value="userType.id">{{ userType.title }}</option>
              </select>
              <div class="invalid-feedback">{{ errors.first('user_type_id') }}</div>
            </div>
            <div class="form-group col">
              <label for="email">Email Address <span class="text-danger">*</span></label>
              <input type="text" name="email" id="email"
                :class="{'form-control': true, 'is-invalid': errors.has('email')}"
                v-validate.disable="{ required: true }"
                v-model="postData.email">
              <div class="invalid-feedback">{{ errors.first('email') }}</div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <label for="password">Password <span class="text-danger">*</span></label>
              <input type="password" name="password" id="password"
                :class="{'form-control': true, 'is-invalid': errors.has('password')}"
                v-validate.disable="{ required: true }"
                v-model="postData.password">
              <div class="invalid-feedback">{{ errors.first('password') }}</div>
            </div>
            <div class="form-group col">
              <label for="password_confirmation">Retype Password <span class="text-danger">*</span></label>
              <input type="password" name="password_confirmation" id="password_confirmation"
                :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
                v-validate.disable="{ required: true }"
                v-model="postData.password_confirmation">
              <div class="invalid-feedback">{{ errors.first('password_confirmation') }}</div>
            </div>
          </div>

          <div class="custom-control custom-checkbox mb-2">
            <input type="checkbox" class="custom-control-input" id="send_welcome" v-model="postData.send_welcome">
            <label class="custom-control-label" for="send_welcome">Send a welcome email to user</label>
          </div>

          <div class="pt-2">
            <beat-loader v-if="state == 'saving'"></beat-loader>
            <template v-else>
              <button type="submit" class="btn btn-success mr-2"><i class="fa fa-save"></i> Submit</button>
              <button type="button" class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
            </template>
          </div>
        </form>
      </template>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'

import ServiceUser from '@/services/user'

export default {
  components: {
    BeatLoader,
  },
  data () {
    return {
      state: '',
      postData: false,
    }
  },
  props: ['value'],
  computed: mapState({
    userTypes: state => state.setting.userTypes
  }),
  watch: {
    value ( newVal ) {
      let self = this;
      if ( newVal ) {
        self.$modal.show('modal-add-user', {});
        setTimeout(() => {
          self.$refs.inputName.focus();
        }, 200);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.resetForm();
    },
    resetForm () {
      let self = this;
      self.postData = {
        first_name: '',
        last_name: '',
        user_type_id: '',
        email: '',
        password: '',
        password_confirmation: '',
        send_welcome: true,
      };
    },
    closeModal () {
      this.$modal.hide('modal-add-user');
    },
    doCreate ( ) {
      let self = this;
      if (self.state == 'saving') {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            self.state = 'saving';
            ServiceUser.create(self.postData, user => {
              self.state = '';
              self.$notify({
                type: 'success',
                text: 'New user added successfully.'
              });
              self.resetForm();
              self.$emit('user-added', user);
              self.closeModal();
            }, () => {
              self.state = '';
            });
          }
        });
    }
  }
}
</script>
