<template>
  <modal name="modal-edit-usertype" height="auto" :width="400"
    :scrollable="true" :clickToClose="false" @closed="$emit('input', false)">
    <div class="p-4">
      <h5 class="border-bottom pb-1 mb-3"><strong>Edit User Type</strong></h5>
      <form method="post" @submit.prevent="doSave">
        <div class="form-group">
          <label for="title">Title <span class="text-danger">*</span></label>
          <input type="text" name="title" id="title" ref="inputTitle"
            :class="{'form-control': true, 'is-invalid': errors.has('title')}"
            v-validate.disable="{ required: true }"
            v-model="title">
          <div class="invalid-feedback">{{ errors.first('title') }}</div>
        </div>

        <div class="pt-2">
          <beat-loader v-if="state == 'saving'"></beat-loader>
          <template v-else>
            <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-save"></i> Save Changes</button>
            <button type="button" class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
          </template>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'

import ServiceUserType from '@/services/usertype'

export default {
  components: {
    BeatLoader,
  },
  data () {
    return {
      state: '',
      title: '',
    }
  },
  props: ['value', 'usertype'],
  computed: mapState({
  }),
  watch: {
    usertype: 'init',
    value ( newVal ) {
      let self = this;
      if ( newVal ) {
        this.$modal.show('modal-edit-usertype', {});
        setTimeout(() => {
          self.$refs.inputTitle.focus();
        }, 200);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      if ( !self.usertype ) {
        return;
      }
      self.title = self.usertype.title;
    },
    closeModal () {
      this.$modal.hide('modal-edit-usertype');
    },
    doSave ( ) {
      let self = this;

      if ( self.state == 'saving' ) {
        return;
      }

      let postData = {};

      if ( self.title != self.usertype.title )
        postData.title = self.title;

      self.state = 'saving';
      ServiceUserType.save(self.usertype.id, postData, userType => {
        self.state = '';
        self.$notify({
          type: 'success',
          text: 'User type updated'
        });
        self.$emit('usertype-saved', userType);
        self.closeModal();
      }, () => {
        self.state = '';
      });
    }
  }
}
</script>
