<template>
  <div class="comp-posts" v-if="settings">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0">{{ settings.pluralName }}</span>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="title">
            <a href="" class="font-weight-bold">Hello world</a>
          </div>

          <div class="status">
            <a href="" class="btn btn-sm btn-success"><i class="fa fa-check"></i> Publish</a>
          </div>

          <div class="actions">
            <a href="" class="btn btn-sm btn-outline-info"><i class="fa fa-pencil"></i></a>
            <a href="" class="btn btn-sm btn-outline-danger ml-1"><i class="fa fa-trash"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="title">
            <a href="" class="font-weight-bold">Hello world</a>
          </div>

          <div class="status">
            <a href="" class="btn btn-sm btn-warning"><i class="fa fa-ban"></i> Pending</a>
          </div>

          <div class="actions">
            <a href="" class="btn btn-sm btn-outline-info"><i class="fa fa-pencil"></i></a>
            <a href="" class="btn btn-sm btn-outline-danger ml-1"><i class="fa fa-trash"></i></a>
          </div>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PostsListing',
  data () {
    return {
      settings: null,
      state: 'loading',
      currentPage: 1,
      totalPages: 0,
      posts: [],
    };
  },
  props: ['type'],
  computed: {},
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.getPosts();
    },
    async getPosts () {
      let self = this;
      let params = {
        page: self.currentPage,
      }
      let resp = await axios.get( '/posts/' + self.type + '/init' );

      self.settings = resp.data;

      let { data } = await axios.get( '/posts/' + self.type, params );

      self.posts = data.data;
      self.totalPages = data.last_page;
    }
  }
}
</script>

<style lang="scss">
.card-post {

}
</style>
