<template>
  <div class="comp-notfound">
    <h3>Not Found</h3>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  watch: {},
  computed: {},
  created () {},
  mounted () {},
  methods: {
    init () {

    }
  }
}
</script>
