<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <router-link class="navbar-brand col-sm-3 col-md-2 mr-0"
      :to="{ name: 'dashboard' }">{{ env.VUE_APP_NAME }}</router-link>
    <form v-if="false" @submit.prevent="doSearch" class="w-100">
      <input class="form-control form-control-dark" type="text" placeholder="Search" aria-label="Search"
        v-model="keywords" ref="input_kw">
      <button v-show="false" type="submit">Search</button>
    </form>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a href="javascript:;" class="nav-link" @click="logout">Logout</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      env: process.env
    }
  },
  methods: {
  }
}
</script>
