<template>
  <div class="w-50 setting-usertypes">

    <div class="table-responsive">
      <table class="table">
        <colgroup>
          <col width="50">
          <col>
          <col width="100">
          <col width="100">
        </colgroup>
        <tbody>
          <tr v-for="(userType, index) in userTypes" :key="index">
            <td>{{ index + 1 }}.</td>
            <td>
              <strong>{{ userType.title }}</strong>
            </td>
            <td align="right">
              <button type="button" class="btn btn-outline-info btn-sm" title="Edit User Type"
                @click="editUserType( index )"><i class="fa fa-edit"></i> Edit</button>
            </td>
            <td align="left">
              <button type="button" class="btn btn-outline-danger btn-sm" title="Delete User Type"
                v-if="userType.deletable" @click="confDelete( index )"><i class="fa fa-trash"></i> Delete</button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="4">
              <button type="button" class="btn btn-outline-success" @click="showModalAdd = true">
                <i class="fa fa-plus"></i> Add New</button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <modal-add-user-type v-model="showModalAdd"
      @usertype-added="onUserTypeAdded"></modal-add-user-type>
    <modal-edit-user-type v-model="showModalEdit"
      :usertype="activeItem" @usertype-saved="onUserTypeSaved"></modal-edit-user-type>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ServiceUserType from '@/services/usertype'
import ModalAddUserType from '@/components/global/ModalAddUserType'
import ModalEditUserType from './ModalEditUserType'

export default {
  name: 'SettingsUserTypes',
  components: {
    ModalAddUserType,
    ModalEditUserType,
  },
  data () {
    return {
      showModalAdd: false,
      showModalEdit: false,
      activeIndex: -1,
    }
  },
  computed: mapState({
    userTypes: state => state.setting.userTypes,
    activeItem () {
      let self = this;
      return self.userTypes[self.activeIndex] ? self.userTypes[self.activeIndex] : null;
    },
  }),
  mounted () {
  },
  methods: {
    editUserType ( index ) {
      let self = this;
      self.activeIndex = index;

      self.showModalEdit = true;
    },
    onUserTypeSaved ( userType ) {
      let self = this, userTypes = self.userTypes;

      userTypes.splice( self.activeIndex, 1, userType );
      self.$store.dispatch( 'setUserTypes', userTypes );
    },
    onUserTypeAdded ( userType ) {
      let self = this, userTypes = self.userTypes;
      userTypes.push( userType );
      self.$store.dispatch( 'setUserTypes', userTypes );
    },
    confDelete ( index ) {
      let self = this, item = self.userTypes[index];

      self.$dialog
        .confirm('Are you sure you want to delete user type "<strong>' + item.title + '</strong>"?', {
          html: true,
          loader: true,
          okText: "Yes, I'm sure",
          cancelText: "Cancel",
        })
        .then(dialog => {
          ServiceUserType.delete(item.id, resp => {
            dialog.close();

            if ( resp.status == 'SUCCESS' ) {
              self.$notify({
                type: 'success',
                text: '<strong>' + item.title + '</strong> deleted'
              });

              let userTypes = self.userTypes;
              userTypes.splice( index, 1 );
              self.$store.dispatch( 'setUserTypes', userTypes );
            }
            else {
              self.$notify({
                type: 'error',
                text: resp.error
              });
            }
          });
        })
        .catch(() => {})
      ;
    },
  }
}
</script>
