<template>
  <div class="settings">
    General settings
  </div>
</template>

<script>
export default {
  name: 'SettingsGeneral',
  data () {
    return {

    }
  },
  watch: {},
  computed: {},
  created () {},
  mounted () {},
  methods: {
    init () {

    }
  }
}
</script>