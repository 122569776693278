<template>
  <modal :name="modalName" height="auto" :width="600"
    :scrollable="true" :clickToClose="false" @closed="$emit('input', false)">
    <div class="p-4">
      <h5 class="border-bottom pb-2 mb-3"><strong>Add Page</strong></h5>
      <template v-if="postData">
        <form method="post" action="" ref="form" @submit.prevent="doCreate">
          <div class="form-group">
            <label for="title">Page Title <span class="text-danger">*</span></label>
            <input type="text" name="title" id="title" ref="inputTitle"
              :class="{'form-control': true, 'is-invalid': errors.has('title')}"
              v-validate.disable="{ required: true }"
              v-model="postData.title">
            <div class="invalid-feedback">{{ errors.first('title') }}</div>
          </div>

          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="isHome" v-model="postData.is_home">
              <label class="custom-control-label" for="isHome">Set as home page</label>
            </div>
          </div>

          <div class="pt-2">
            <beat-loader v-if="state == 'saving'"></beat-loader>
            <template v-else>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-save"></i> Submit</button>
              <button type="button" class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
            </template>
          </div>
        </form>
      </template>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'

import ServiceBrandPage from '@/services/brandpage'

export default {
  components: {
    BeatLoader,
  },
  data () {
    return {
      modalName: 'modal-add-brand-page',
      state: '',
      postData: false,
    }
  },
  props: ['value'],
  computed: mapState({
  }),
  watch: {
    value ( newVal ) {
      let self = this;
      if ( newVal ) {
        self.$modal.show(self.modalName, {});
        setTimeout(() => {
          self.$refs.inputTitle.focus();
        }, 200);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.resetForm();
    },
    resetForm () {
      let self = this;
      self.postData = {
        title: '',
        is_home: false,
      };
    },
    closeModal () {
      let self = this;
      this.$modal.hide(self.modalName);
    },
    doCreate ( ) {
      let self = this;

      if (self.state == 'saving') {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            self.state = 'saving';
            ServiceBrandPage.create(self.postData, page => {
              self.state = '';
              self.$notify({
                type: 'success',
                text: 'Page "<strong>' + page.title + '</strong>" added'
              });
              self.resetForm();
              self.$emit('brand-page-added', page);
              self.closeModal();
            }, () => {
              self.state = '';
            });
          }
        });
    }
  }
}
</script>
