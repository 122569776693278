import Account from '@/components/account'
import AccountProfile from '@/components/account/Profile'
import AccountPassword from '@/components/account/Password'

export default {
  path: '/account',
  component: Account,
  children: [
    {
      path: '',
      name: 'account',
      component: AccountProfile,
    },
    {
      path: 'password',
      name: 'account-password',
      component: AccountPassword,
    },
  ]
}
