import axios from 'axios'

export default {
  getUsers ( params, callback ) {
    axios.get('/users', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getUser ( userId, callback, fallback ) {
    axios.get('/users/' + userId)
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback(error);
      });
  },
  create ( data, callback, fallback ) {
    axios.post( '/users', data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback(error);
      });
  },
  save ( userId, data, callback, fallback ) {
    axios.post( '/users/' + userId, data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  delete ( userId, callback, fallback ) {
    axios.delete( '/users/' + userId )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  deleteMany ( userIds, callback, fallback ) {
    axios.post( '/users/delete', { ids: userIds })
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  updateUserAccount ( data, callback, fallback ) {
    axios.post( '/user', data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  saveCompany ( data, callback, fallback ) {
    axios.post( '/user/company', data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
}
