<template>
  <div class="component">
    Edit Modal
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  computed: {},
  mounted () {
    let self = this;
    self.init();
  },
  methods: {
    init () {
    },
  }
}
</script>
