import Settings from '@/components/settings'
import SettingGeneral from '@/components/settings/General'
import SettingUserTypes from '@/components/settings/UserTypes'

export default {
  path: '/settings',
  component: Settings,
  children: [
    {
      path: '',
      name: 'settings',
      component: SettingGeneral
    },
    {
      path: 'user-types',
      name: 'setting-user-types',
      component: SettingUserTypes
    },
  ]
}
