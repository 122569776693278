<template>
  <div class="comp-settings">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-cogs"></i> Settings</span>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <router-link :to="{ name: tab.name }" class="nav-link"
          exact-active-class="active">{{ tab.label }}</router-link>
      </li>
    </ul>

    <div class="p-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsIndex',
  data () {
    return {
      tabs: [
        { name: 'settings', label: 'General' },
        { name: 'setting-user-types', label: 'User Types' },
      ],
    }
  },
  mounted () {},
  methods: {
    init () {
    }
  }
}
</script>
