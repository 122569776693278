<template>
  <div class="comp-aftermarket">
    <h2 class="mb-4"><i class="fa fa-table"></i> Aftermarket</h2>

    <div class="table-responsive">
      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-dark">
          <tr>
            <th width="95"></th>
            <th width="40%">Product</th>
            <th width="20%">Product Code</th>
            <th width="15%">Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="index">
            <td>
              <button type="button" class="btn btn-outline-dark mr-1" title="Move Up"
                :disabled="index==0 || rows.length<=1 || state!=''"
                @click="moveUp(index)"><i class="fa fa-arrow-up"></i></button>
              <button type="button" class="btn btn-outline-dark" title="Move Down"
                :disabled="index==rows.length-1 || rows.length<=1 || state!=''"
                @click="moveDown(index)"><i class="fa fa-arrow-down"></i></button>
            </td>
            <td>
              <input type="text" class="form-control" v-model="row.product">
            </td>
            <td>
              <input type="text" class="form-control" v-model="row.code">
            </td>
            <td>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" v-model="row.price_formatted">
              </div>
            </td>
            <td>
              <button type="button" class="btn btn-outline-danger mr-1" title="Delete"
                :disabled="state!=''"
                @click="confDelete( index )"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="state!='loading'">
          <tr>
            <th></th>
            <th colspan="4">
              <button type="button" class="btn btn-primary mr-2" :disabled="state!=''"
                @click="save"><i class="fa fa-save"></i> Save Changes</button>
              <button type="button" class="btn btn-success" :disabled="state!=''"
                @click="addRow"><i class="fa fa-plus"></i> Add Row</button>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AftermarketIndex',
  data () {
    return {
      state: '',
      rows: []
    }
  },
  computed: {},
  mounted () {
    let self = this
    self.init()
  },
  methods: {
    init () {
      let self = this

      self.state = 'loading'

      axios.get('/aftermarket')
      .then(resp => {
        self.state = ''
        self.rows = resp.data
      })
      .catch(() => {})
    },
    addRow () {
      let self = this

      self.state = 'adding'
      axios.post( '/aftermarket' )
      .then(resp => {
        self.state = ''
        self.rows.push( resp.data )
      })
      .catch(() => {})
    },
    moveUp ( index ) {
      let self = this,
        temp = self.rows[index-1]

      self.rows.splice(index-1, 1)
      self.rows.splice(index, 0, temp)
    },
    moveDown ( index ) {
      let self = this,
        temp = self.rows[index+1]

      self.rows.splice(index+1, 1)
      self.rows.splice(index, 0, temp)
    },
    save () {
      let self = this
      self.state = 'saving'
      axios.post( '/aftermarket/update', {
        rows: self.rows
      })
      .then(resp => {
        self.state = ''

        self.rows = resp.data

        self.$notify({
          type: 'success',
          text: 'Changes saved successfully'
        })
      })
      .catch(() => {})
    },
    confDelete ( index ) {
      let self = this, item = self.rows[index]
      self.$dialog
        .confirm('Are you sure you want to delete this row?', {
          html: true,
          loader: true,
          okText: "Yes, I'm sure",
          cancelText: "Cancel",
        })
        .then(dialog => {
          axios.delete( '/aftermarket/' + item.id )
          // eslint-disable-next-line
          .then(resp => {
            dialog.close()

            self.rows = resp.data

            self.$notify({
              type: 'success',
              text: '<strong>' + item.product + '</strong> is deleted'
            })
          })
          .catch(() => {})
        })
        .catch(() => {})
      ;
    },
  }
}
</script>
