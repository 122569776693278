<template>
  <nav>
    <ul class="pagination pagination-sm mb-0" v-show="totalPages > 1">
      <li :class="{ 'page-item': true, 'disabled': (currentPage == 1) }">
        <span class="page-link" v-if="currentPage == 1">&laquo;</span>
        <a class="page-link" href="javascript:;" v-if="currentPage > 1"
          @click="goToPage(1)">&laquo;</a>
      </li>
      <li :class="{ 'page-item': true, 'disabled': (currentPage == 1) }">
        <span class="page-link" v-if="currentPage == 1">&lsaquo;</span>
        <a class="page-link" href="javascript:;" v-if="currentPage > 1"
          @click="goToPage(currentPage - 1)">&lsaquo;</a>
      </li>

      <template v-if="totalPages < milestone">
        <li v-for="(n, index) in totalPages" :key="index"
          :class="{ 'page-item': true, 'active': (n == currentPage) }">
          <span class="page-link" v-if="n == currentPage">{{ n }}</span>
          <a class="page-link" href="javascript:;" v-if="n != currentPage"
            @click="goToPage(n)">{{ n }}</a>
        </li>
      </template>

      <template v-if="(totalPages >= milestone) && (currentPage <= 4)">
        <li v-for="(n, index) in 6" :key="index"
          :class="{ 'page-item': true, 'active': (n == currentPage) }">
          <span class="page-link" v-if="n == currentPage">{{ n }}</span>
          <a class="page-link" href="javascript:;" v-if="n != currentPage"
            @click="goToPage(n)">{{ n }}</a>
        </li>
        <li class="page-item disabled"><span class="page-link">&hellip;</span></li>
        <li class="page-item"><a class="page-link" href="javascript:;" @click="goToPage(totalPages)">{{ totalPages }}</a></li>
      </template>

      <template v-if="(totalPages >= milestone) && (currentPage > 4) && (currentPage <= totalPages - 4)">
        <li class="page-item"><a class="page-link" href="javascript:;" @click="goToPage(1)">1</a></li>
        <li class="page-item disabled"><span class="page-link">&hellip;</span></li>
        <li v-for="(n, index) in 5" :key="index"
          :class="{ 'page-item': true, 'active': (n == 3) }">
          <span class="page-link" v-if="n == 3">{{ currentPage + n - 3 }}</span>
          <a class="page-link" href="javascript:;" v-if="n != 3"
            @click="goToPage(currentPage + n - 3)">{{ currentPage + n - 3 }}</a>
        </li>
        <li class="page-item disabled"><span class="page-link">&hellip;</span></li>
        <li class="page-item"><a class="page-link" href="javascript:;" @click="goToPage(totalPages)">{{ totalPages }}</a></li>
      </template>

      <template v-if="(totalPages >= milestone) && (currentPage > totalPages - 4)">
        <li class="page-item"><a class="page-link" href="javascript:;" @click="goToPage(1)">1</a></li>
        <li class="page-item disabled"><span class="page-link">&hellip;</span></li>
        <li v-for="(n, index) in 6" :key="index"
          :class="{ 'page-item': true, 'active': (currentPage == totalPages + n - 6) }">
          <span class="page-link" v-if="(currentPage == totalPages + n - 6)">{{ totalPages + n - 6 }}</span>
          <a class="page-link" href="javascript:;" v-if="(currentPage != totalPages + n - 6)"
            @click="goToPage(totalPages + n - 6)">{{ totalPages + n - 6 }}</a>
        </li>
      </template>

      <li :class="{ 'page-item': true, 'disabled': (currentPage == totalPages) }">
        <span class="page-link" v-if="currentPage == totalPages">&rsaquo;</span>
        <a class="page-link" href="javascript:;" v-if="currentPage < totalPages"
          @click="goToPage(currentPage + 1)">&rsaquo;</a>
      </li>
      <li :class="{ 'page-item': true, 'disabled': (currentPage == totalPages) }">
        <span class="page-link" v-if="currentPage == totalPages">&raquo;</span>
        <a class="page-link" href="javascript:;" v-if="currentPage < totalPages"
          @click="goToPage(totalPages)">&raquo;</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'GlobalPagination',
  data () {
    return {
      milestone: 8
      // currentPage: 1
    };
  },
  props: [
    'totalPages', 'currentPage'
  ],
  mounted () {
  },
  methods: {
    goToPage (page) {
      this.$emit('goto-page', page);
    }
  }
}
</script>
