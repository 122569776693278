<template>
  <div class="account-password w-25">
    <form method="post" @submit.prevent="doSave">
      <div class="form-group">
        <label for="password">New Password <span class="text-danger">*</span></label>
        <input type="password" name="password" id="password"
          :class="{'form-control': true, 'is-invalid': errors.has('password')}"
          v-validate.disable="{ required: true }" v-model="password">
        <div class="invalid-feedback">{{ errors.first('password') }}</div>
      </div>

      <div class="form-group">
        <label for="password_confirmation">Retype New Password <span class="text-danger">*</span></label>
        <input type="password" name="password_confirmation" id="password_confirmation"
          :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
          v-validate.disable="{ required: true }" v-model="password_confirmation">
        <div class="invalid-feedback">{{ errors.first('password_confirmation') }}</div>
      </div>

      <div class="">
        <beat-loader v-if="status == 'saving'"></beat-loader>
        <template v-else>
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Update Password</button>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import BeatLoader from 'vue-spinner/src/BeatLoader'
import ServiceUser from '@/services/user'

export default {
  name: 'AccountPassword',
  components: {
    BeatLoader,
  },
  data () {
    return {
      status: '',
      password: '',
      password_confirmation: '',
    }
  },
  mounted () {
  },
  methods: {
    doSave ( ) {
      let self = this;

      if ( self.status == 'saving' ) {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            let postData = {
              password: self.password,
              password_confirmation: self.password_confirmation,
            };

            self.status = 'saving';

            ServiceUser.updateUserAccount(postData, () => {
              self.status = '';
              self.password = '';
              self.password_confirmation = '';

              self.$notify({
                type: 'success',
                text: 'Password updated successfully'
              });
            }, () => {
              self.status = '';
            });
          }
        });
    }
  }
}
</script>
