<template>
  <div class="account-profile w-50">
    <form method="post" @submit.prevent="doSave">
      <div class="form-row">
        <div class="form-group col">
          <label for="first_name">First Name <span class="text-danger">*</span></label>
          <input type="text" name="first_name" id="first_name" ref="inputName"
            :class="{'form-control': true, 'is-invalid': errors.has('first_name')}"
            v-validate.disable="{ required: true }"
            v-model="first_name">
          <div class="invalid-feedback">{{ errors.first('first_name') }}</div>
        </div>
        <div class="form-group col">
          <label for="last_name">Last Name <span class="text-danger">*</span></label>
          <input type="text" name="last_name" id="last_name"
            :class="{'form-control': true, 'is-invalid': errors.has('last_name')}"
            v-validate.disable="{ required: true }"
            v-model="last_name">
          <div class="invalid-feedback">{{ errors.first('last_name') }}</div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
          <label for="email">Email Address <span class="text-danger">*</span></label>
          <input type="text" name="email" id="email"
            :class="{'form-control': true, 'is-invalid': errors.has('email')}"
            v-validate.disable="{ required: true }"
            v-model="email">
          <div class="invalid-feedback">{{ errors.first('email') }}</div>
        </div>
        <div class="form-group col">
          <label for="Phone">Phone</label>
          <input type="text" name="phone" id="phone" :class="{'form-control': true}" v-model="phone">
        </div>
      </div>

      <div class="">
        <beat-loader v-if="status == 'saving'"></beat-loader>
        <template v-else>
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Save Changes</button>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'
import ServiceUser from '@/services/user'

export default {
  name: 'AccountProfile',
  components: {
    BeatLoader,
  },
  data () {
    return {
      status: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
    }
  },
  watch: {
    currentUser: 'init'
  },
  computed: mapState({
    currentUser: state => state.setting.currentUser
  }),
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      if ( !self.currentUser ) return;

      self.first_name = self.currentUser.first_name;
      self.last_name = self.currentUser.last_name;
      self.email = self.currentUser.email;
      self.phone = self.currentUser.phone;
    },
    doSave ( ) {
      let self = this;

      if ( self.status == 'saving' ) {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            let postData = {
              first_name: self.first_name,
              last_name: self.last_name,
              email: self.email,
              phone: self.phone,
            };

            self.status = 'saving';

            ServiceUser.updateUserAccount(postData, user => {
              self.status = '';
              self.$notify({
                type: 'success',
                text: 'Profile updated successfully'
              });
              self.$store.dispatch( 'setCurrentUser', user );
            }, () => {
              self.status = '';
            });
          }
        });
    }
  }
}
</script>
