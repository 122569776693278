<template>
  <nav class="col-md-2 d-none d-md-block sidebar">
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item" v-for="(mainMenuItem, index) in mainMenuItems" :key="index">
          <router-link class="nav-link" exact-active-class="active" :to="{ name: mainMenuItem.route }">
            <i :class="'fa fa-fw fa-' + mainMenuItem.icon"></i>
            {{ mainMenuItem.label }}
          </router-link>
        </li>
      </ul>

      <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>MY ACCOUNT</span>
      </h6>
      <ul class="nav flex-column mb-2">
        <li class="nav-item">
          <router-link class="nav-link" exact-active-class="active"
            :to="{name: 'account'}"><i class="fa fa-fw fa-user-circle"></i> Edit profile</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" exact-active-class="active"
            :to="{name: 'account-password'}"><i class="fa fa-fw fa-key"></i> Change password</router-link>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" @click="logout">
            <i class="fa fa-fw fa-sign-out"></i> Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      mainMenuItems: [
        { icon: 'home', label: 'Dashboard', route: 'dashboard' },
        // { icon: 'file', label: 'Pages', route: 'pages' },
        // { icon: 'files-o', label: 'Brand Pages', route: 'brand' },
        { icon: 'puzzle-piece', label: 'Redbook', route: 'redbook' },
        { icon: 'table', label: 'Aftermarket', route: 'aftermarket' },
        { icon: 'users', label: 'Users', route: 'users' },
        { icon: 'cogs', label: 'Settings', route: 'settings' },
      ],
    }
  },
  watch: {},
  computed: {},
  created () {},
  mounted () {},
  methods: {
  }
}
</script>
