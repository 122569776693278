<template>
  <modal name="modal-add-usertype" height="auto" :width="400"
    :scrollable="true" :clickToClose="false" @closed="$emit('input', false)">
    <div class="p-4">
      <h5 class="border-bottom pb-2 mb-3"><strong>Add User Type</strong></h5>
      <template v-if="postData">
        <form method="post" action="" ref="form" @submit.prevent="doCreate">
          <div class="form-group">
            <label for="title">Title <span class="text-danger">*</span></label>
            <input type="text" name="title" id="title" ref="inputTitle"
              :class="{'form-control': true, 'is-invalid': errors.has('title')}"
              v-validate.disable="{ required: true }"
              v-model="postData.title">
            <div class="invalid-feedback">{{ errors.first('title') }}</div>
          </div>

          <div class="pt-2">
            <beat-loader v-if="state == 'saving'"></beat-loader>
            <template v-else>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-save"></i> Submit</button>
              <button type="button" class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
            </template>
          </div>
        </form>
      </template>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'

import ServiceUserType from '@/services/usertype'

export default {
  components: {
    BeatLoader,
  },
  data () {
    return {
      state: '',
      postData: false,
    }
  },
  props: ['value'],
  computed: mapState({
  }),
  watch: {
    value ( newVal ) {
      let self = this;
      if ( newVal ) {
        self.$modal.show('modal-add-usertype', {});
        setTimeout(() => {
          self.$refs.inputTitle.focus();
        }, 200);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.resetForm();
    },
    resetForm () {
      let self = this;
      self.postData = {
        title: '',
      };
    },
    closeModal () {
      this.$modal.hide('modal-add-usertype');
    },
    doCreate ( ) {
      let self = this;

      if (self.state == 'saving') {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            self.state = 'saving';
            ServiceUserType.create(self.postData, userType => {
              self.state = '';
              self.$notify({
                type: 'success',
                text: 'User type <strong>' + userType.title + '</strong> added'
              });
              self.resetForm();
              self.$emit('usertype-added', userType);
              self.closeModal();
            }, () => {
              self.state = '';
            });
          }
        });
    }
  }
}
</script>
