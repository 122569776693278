<template>
  <div class="comp-users">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-users"></i> Users ({{ totalItems }})</span>
      <button type="button" class="btn btn-outline-success" @click="showModalAdd = true">
        <i class="fa fa-plus"></i> Add User</button>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <div class="links mr-3">
          <a href="javascript:;" @click="doViewAll">View All</a>
        </div>
        <div class="mr-2">
          <select v-model="ftUserType" class="form-control">
            <option value="">- All types</option>
            <option v-for="(userType, index) in userTypes" :key="index"
              :value="userType.id">{{ userType.title }}</option>
          </select>
        </div>
        <button type="button" class="btn btn-secondary" @click="doPaging(1)">Filter</button>
      </div>
      <form method="get" @submit.prevent="doPaging(1)">
        <div class="d-flex align-items-center">
          <input type="text" class="form-control mr-2" v-model="keyword">
          <button class="btn btn-secondary">Search</button>
        </div>
      </form>
    </div>

    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th width="30"><input type="checkbox" @click="toggleAll" :checked="isCheckedAll"></th>
            <th>User</th>
            <th>User Type</th>
            <th>Role</th>
            <th>Email</th>
            <th>Phone</th>
            <th width="100" class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="index">
            <td><input type="checkbox" @click="toggleOne( user.id )" :checked="isChecked( user.id )"></td>
            <td>
              <!-- <img :src="imageFitUrl(40, user.photo ? user.photo.path : '')" class="rounded-circle mr-1"> -->
              <a href="javascript:;" @click="editUser( index )">
                <strong>{{ user.full_name }}</strong>
              </a>
            </td>
            <td>
              <span :class="['badge', 'badge-' + (user.user_type.color_class ? user.user_type.color_class : 'secondary')]">
                {{ user.user_type.title }}</span>
            </td>
            <td>{{ user.role }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone }}</td>
            <td align="right">
              <button type="button" class="btn btn-outline-danger btn-sm" title="Delete User"
                @click="confDelete( index )"><i class="fa fa-trash"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="hasFilters && users.length == 0" class="alert alert-warning">
      No users found
    </div>

    <div class="d-flex justify-content-between">
      <div class="actions d-flex">
        <template v-if="checkedIds.length > 0">
          <select class="form-control mr-2" v-model="bulkAction">
            <option value="">- Bulk action -</option>
            <option value="delete">Delete selected items</option>
          </select>
          <button type="button" class="btn btn-info" :disabled="bulkAction == ''" @click="doBulkAction">Action</button>
        </template>
      </div>
      <global-pagination :total-pages="totalPages" :current-page="currentPage"
        @goto-page="doPaging"></global-pagination>
    </div>

    <modal-add-user v-model="showModalAdd" @user-added="onUserAdded"></modal-add-user>
    <modal-edit-user v-model="showModalEdit" :user="activeUser" @user-saved="onUserSaved"></modal-edit-user>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { map, indexOf } from 'lodash'
import ServiceUser from '@/services/user'
import ModalAddUser from '@/components/global/ModalAddUser'
import ModalEditUser from './ModalEditUser'

export default {
  name: 'UsersIndex',
  components: {
    ModalAddUser,
    ModalEditUser,
  },
  data () {
    return {
      showModalAdd: false,
      showModalEdit: false,
      state: 'loading',
      keyword: '',
      activeIndex: -1,
      ftUserType: '',
      totalItems: 0,
      currentPage: 1,
      totalPages: 0,
      users: [],
      checkedIds: [],
      bulkAction: '',
    }
  },
  computed: mapState({
    userTypes: state => state.setting.userTypes,
    activeUser () {
      let self = this;
      return self.users[self.activeIndex] ? self.users[self.activeIndex] : null;
    },
    hasFilters () {
      let self = this;
      return self.ftUserType != '' || self.keyword != '';
    },
    isCheckedAll () {
      let self = this;
      return self.users.length > 0 && self.users.length == self.checkedIds.length;
    },
  }),
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.getUsers();
    },
    isChecked ( userId ) {
      let self = this;
      return indexOf(self.checkedIds, userId) >= 0;
    },
    toggleOne ( userId ) {
      let self = this, index = indexOf(self.checkedIds, userId);
      if ( index >= 0 ) {
        self.checkedIds.splice( index, 1 );
      }
      else {
        self.checkedIds.push( userId );
      }
    },
    toggleAll () {
      let self = this;
      if ( self.isCheckedAll ) {
        self.checkedIds = [];
      }
      else {
        self.checkedIds = map(self.users, 'id');
      }
    },
    editUser ( index ) {
      let self = this;
      self.activeIndex = index;

      self.showModalEdit = true;
    },
    onUserSaved ( user ) {
      let self = this;
      self.users.splice( self.activeIndex, 1, user );
    },
    onUserAdded ( user ) {
      let self = this;
      self.users.unshift( user );
    },
    doBulkAction () {
      let self = this;
      if ( self.bulkAction == 'delete' ) {
        self.$dialog
          .confirm('Are you sure you want to delete selected users?', {
            html: true,
            loader: true,
            okText: "Yes, I'm sure",
            cancelText: "Cancel",
          })
          .then(dialog => {
            ServiceUser.deleteMany(self.checkedIds, () => {
              dialog.close();
              self.$notify({
                type: 'success',
                text: 'Selected users deleted'
              });
              self.bulkAction = '';
              self.getUsers();
            });
          })
          .catch(() => {})
        ;
      }
    },
    confDelete ( index ) {
      let self = this, item = self.users[index];
      self.$dialog
        .confirm('Are you sure you want to delete <strong>' + item.name + '</strong>?', {
          html: true,
          loader: true,
          okText: "Yes, I'm sure",
          cancelText: "Cancel",
        })
        .then(dialog => {
          ServiceUser.delete(item.id, () => {
            dialog.close();
            self.users.splice(index, 1);
            self.$notify({
              type: 'success',
              text: '<strong>' + item.name + '</strong> deleted'
            });
          });
        })
        .catch(() => {})
      ;
    },
    doViewAll () {
      let self = this;
      self.currentPage = 1;
      self.keyword = '';
      self.ftUserType = '';
      self.getUsers();
    },
    doPaging ( page ) {
      let self = this;
      self.currentPage = page;
      self.getUsers();
    },
    getUsers () {
      let self = this,
        params = {
          page: self.currentPage,
        };

      if ( self.ftUserType !== '' ) {
        params.user_type_id = self.ftUserType;
      }
      if ( self.keyword !== '' ) {
        params.kw = self.keyword;
      }

      self.state = 'loading';
      ServiceUser.getUsers(params, resp => {
        self.state = 'loaded';
        self.users = resp.data;
        self.totalPages = resp.last_page;
        self.totalItems = resp.total;
        self.checkedIds = [];
      });
    }
  }
}
</script>
