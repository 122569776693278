<template>
  <div class="redbook">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-puzzle-piece"></i> Redbook Data</span>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <router-link :to="{ name: 'redbook', params: { table: tab.name } }" class="nav-link"
          exact-active-class="active">{{ tab.label }}</router-link>
      </li>
    </ul>

    <div class="p-4">
      <tab-make v-if="table == 'Make'"></tab-make>
      <tab-family v-if="table == 'Family'"></tab-family>
      <tab-year v-if="table == 'Year'"></tab-year>
      <tab-vehicle v-if="table == 'Vehicle'"></tab-vehicle>
      <tab-opt-equip v-if="table == 'OptEquip'"></tab-opt-equip>
      <tab-opt-pk v-if="table == 'OptPk'"></tab-opt-pk>
      <tab-photo v-if="table == 'Photo'"></tab-photo>
      <tab-std-equip v-if="table == 'StdEquip'"></tab-std-equip>
      <tab-price-ahead v-if="table == 'PriceAhead'"></tab-price-ahead>
    </div>
  </div>
</template>

<script>
import TabMake from './TabMake'
import TabFamily from './TabFamily'
import TabYear from './TabYear'
import TabVehicle from './TabVehicle'
import TabOptEquip from './TabOptEquip'
import TabOptPk from './TabOptPk'
import TabPhoto from './TabPhoto'
import TabStdEquip from './TabStdEquip'
import TabPriceAhead from './TabPriceAhead'

export default {
  name: 'RedbookIndex',
  components: {
    TabMake,
    TabFamily,
    TabYear,
    TabVehicle,
    TabOptEquip,
    TabOptPk,
    TabPhoto,
    TabStdEquip,
    TabPriceAhead,
  },
  data () {
    return {
      tabs: [
        { name: 'Make', label: 'Makes' },
        { name: 'Family', label: 'Families' },
        { name: 'Year', label: 'Years' },
        { name: 'Vehicle', label: 'Vehicles' },
        { name: 'OptEquip', label: 'Option Equipments' },
        { name: 'OptPk', label: 'Option Packages' },
        { name: 'Photo', label: 'Photos' },
        { name: 'StdEquip', label: 'Standard Equipments' },
        { name: 'PriceAhead', label: 'Price Ahead' },
      ],
    }
  },
  props: ['table'],
  watch: {
    'table': 'init'
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      if ( !self.table ) {
        self.$router.push( { name: 'redbook', params: { table: 'Make' } } );
        return;
      }
    },
  }
}
</script>
