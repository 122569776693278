import Vue from 'vue'
import ServiceAuth from '@/services/auth'

Vue.mixin({
  methods: {
    setSettings ( data ) {
      let self = this;

      self.$store.dispatch( 'setAppSettings', data.app );
      self.$store.dispatch( 'setUserTypes', data.user_types );
      self.$store.dispatch( 'setCurrentUser', data.user );
    },
    logout () {
      let self = this;

      ServiceAuth.logout(() => {
        self.$localStorage.remove( 'access_token' );

        self.$router.push({ name: 'login' });
      });
    }
  }
});
