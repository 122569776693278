import axios from 'axios'

export default {
  getMakes ( params, callback ) {
    axios.get('/redbook/make', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getFamilies ( params, callback ) {
    axios.get('/redbook/family', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getYears ( params, callback ) {
    axios.get('/redbook/year', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getVehicles ( params, callback ) {
    axios.get('/redbook/vehicle', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getOptEquips ( params, callback ) {
    axios.get('/redbook/optequip', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getOptPks ( params, callback ) {
    axios.get('/redbook/optpk', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getPhotos ( params, callback ) {
    axios.get('/redbook/photo', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getStdEquips ( params, callback ) {
    axios.get('/redbook/stdequip', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  getPriceAhead ( params, callback ) {
    axios.get('/redbook/priceahead', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
}
