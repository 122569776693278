import Dashboard from '@/components/dashboard'
import NotFound from '@/components/NotFound'
import Login from '@/components/auth/Login'
import Forgot from '@/components/auth/Forgot'

export default [
  {
    path: '/', name: 'dashboard', component: Dashboard,
  },
  {
    path: '/login', name: 'login', component: Login,
  },
  {
    path: '/forgot', name: 'forgot', component: Forgot,
  },
  {
    path: '/not-found', name: 'not-found', component: NotFound,
  },
  {
    path: '/*', redirect: { name: 'not-found' }
  }
]
