import PostsListing from '@/components/Post/PostsListing'
import Post from '@/components/Post/ModalEdit'

export default {
  path: '/posts/:type',
  props: true,
  component: PostsListing,
  children: [
    // {
    //   path: '', name: 'posts', component: Posts,
    // },
    {
      path: ':id', name: 'post', component: Post,
    },
  ]
}
