import Pages from '@/components/page'

export default {
  path: '/pages',
  name: 'pages',
  component: Pages,
  // children: [
  //   {
  //     path: '',
  //     name: 'account',
  //     component: AccountProfile,
  //   },
  //   {
  //     path: 'password',
  //     name: 'account-password',
  //     component: AccountPassword,
  //   },
  // ]
}
