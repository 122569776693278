import Vue from 'vue'

export default {
  create ( data, callback, fallback ) {
    Vue.axios.post( '/user-types', data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback(error);
      });
  },
  save ( userTypeId, data, callback, fallback ) {
    Vue.axios.post( '/user-types/' + userTypeId, data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  delete ( userTypeId, callback, fallback ) {
    Vue.axios.delete( '/user-types/' + userTypeId )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
}
