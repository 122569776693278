<template>
  <modal name="modal-edit-user" height="auto" :width="560"
    :scrollable="true" :clickToClose="false" @closed="$emit('input', false)">
    <div class="p-4">
      <h5 class="border-bottom pb-1 mb-3"><strong>Edit User</strong></h5>
      <form method="post" @submit.prevent="doSave">
        <div class="form-row">
          <div class="form-group col">
            <label for="first_name">First Name <span class="text-danger">*</span></label>
            <input type="text" name="first_name" id="first_name" ref="inputName"
              :class="{'form-control': true, 'is-invalid': errors.has('first_name')}"
              v-validate.disable="{ required: true }"
              v-model="first_name">
            <div class="invalid-feedback">{{ errors.first('first_name') }}</div>
          </div>
          <div class="form-group col">
            <label for="last_name">Last Name <span class="text-danger">*</span></label>
            <input type="text" name="last_name" id="last_name"
              :class="{'form-control': true, 'is-invalid': errors.has('last_name')}"
              v-validate.disable="{ required: true }"
              v-model="last_name">
            <div class="invalid-feedback">{{ errors.first('last_name') }}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label for="user_type_id">User Type <span class="text-danger">*</span></label>
            <select name="user_type_id" id="user_type_id"
              :class="{'form-control': true, 'is-invalid': errors.has('user_type_id')}"
              v-validate.disable="{ required: true }"
              v-model="user_type_id">
              <option value="">- Select user type</option>
              <option v-for="(userType, index) in userTypes" :key="index" :value="userType.id">{{ userType.title }}</option>
            </select>
            <div class="invalid-feedback">{{ errors.first('user_type_id') }}</div>
          </div>
          <div class="form-group col">
            <label for="email">Email Address <span class="text-danger">*</span></label>
            <input type="text" name="email" id="email"
              :class="{'form-control': true, 'is-invalid': errors.has('email')}"
              v-validate.disable="{ required: true }"
              v-model="email">
            <div class="invalid-feedback">{{ errors.first('email') }}</div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label for="role">Role</label>
            <input type="text" name="role" id="role" :class="{'form-control': true}" v-model="role">
          </div>
          <div class="form-group col">
            <label for="Phone">Phone</label>
            <input type="text" name="phone" id="phone" :class="{'form-control': true}" v-model="phone">
          </div>
        </div>

        <h6 class="border-bottom pt-3 pb-1 mb-3"><strong>Change Password</strong></h6>

        <div class="form-row">
          <div class="form-group col">
            <label for="password">New Password <span class="text-danger">*</span></label>
            <input type="password" name="password" id="password"
              :class="{'form-control': true, 'is-invalid': errors.has('password')}"
              v-validate.disable="{ required: true }" v-model="password">
            <div class="invalid-feedback">{{ errors.first('password') }}</div>
          </div>
          <div class="form-group col">
            <label for="password_confirmation">Retype New Password <span class="text-danger">*</span></label>
            <input type="password" name="password_confirmation" id="password_confirmation"
              :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
              v-validate.disable="{ required: true }" v-model="password_confirmation">
            <div class="invalid-feedback">{{ errors.first('password_confirmation') }}</div>
          </div>
        </div>

        <div class="pt-2">
          <beat-loader v-if="state == 'saving'"></beat-loader>
          <template v-else>
            <button type="submit" class="btn btn-success mr-2"><i class="fa fa-save"></i> Save Changes</button>
            <button type="button" class="btn btn-outline-secondary" @click="closeModal">Cancel</button>
          </template>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'

import ServiceUser from '@/services/user'
// import FileUploader from '@/components/global/FileUploader'

export default {
  components: {
    BeatLoader,
    // FileUploader,
  },
  data () {
    return {
      state: '',
      file: null,
      first_name: '',
      last_name: '',
      user_type_id: '',
      email: '',
      role: '',
      phone: '',
      password: '',
      password_confirmation: '',
    }
  },
  props: ['value', 'user'],
  computed: mapState({
    userTypes: state => state.setting.userTypes,
  }),
  watch: {
    user: 'init',
    value ( newVal ) {
      let self = this;
      if ( newVal ) {
        this.$modal.show('modal-edit-user', {});
        setTimeout(() => {
          self.$refs.inputName.focus();
        }, 200);
      }
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      if ( !self.user ) {
        return;
      }
      // self.file = self.user.file ? self.user.file : null;
      self.first_name = self.user.first_name;
      self.last_name = self.user.last_name;
      self.user_type_id = self.user.user_type_id;
      self.email = self.user.email;
      self.role = self.user.role;
      self.phone = self.user.phone;
      self.password = '';
      self.password_confirmation = '';
    },
    closeModal () {
      this.$modal.hide('modal-edit-user');
    },
    doSave ( ) {
      let self = this;

      if ( self.state == 'saving' ) {
        return;
      }

      let postData = {};

      if ( self.first_name != self.user.first_name )
        postData.first_name = self.first_name;

      if ( self.last_name != self.user.last_name )
        postData.last_name = self.last_name;

      if ( self.user_type_id != self.user.user_type_id )
        postData.user_type_id = self.user_type_id;

      if ( self.email != self.user.email )
        postData.email = self.email;

      if ( self.role != self.user.role )
        postData.role = self.role;

      if ( self.phone != self.user.phone )
        postData.phone = self.phone;

      if ( self.password ) {
        postData.password = self.password;
        postData.password_confirmation = self.password_confirmation;
      }

      self.state = 'saving';
      ServiceUser.save(self.user.id, postData, user => {
        self.state = '';
        self.$notify({
          type: 'success',
          text: 'User updated successfully.'
        });
        self.$emit('user-saved', user);
        self.closeModal();
      }, () => {
        self.state = '';
      });
    }
  }
}
</script>
