export default {
  state: {
    app: [],
    userTypes: [],
    currentUser: false,
  },
  getters: {},
  actions: {
    setAppSettings ( { commit }, data ) {
      commit( 'setAppSettings', data );
    },
    setUserTypes ( { commit }, data ) {
      commit( 'setUserTypes', data );
    },
    setCurrentUser ( { commit }, data ) {
      commit( 'setCurrentUser', data );
    },
  },
  mutations: {
    setAppSettings ( state, data ) {
      state.app = data;
    },
    setUserTypes ( state, data ) {
      state.userTypes = data;
    },
    setCurrentUser ( state, data ) {
      state.currentUser = data;
    },
  }
}
