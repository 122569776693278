<template>
  <div class="tab-make">
    <div v-if="state != 'loading'" class="d-flex justify-content-between align-items-center mb-3">
      <form method="get" @submit.prevent="doPaging(1)">
        <div class="d-flex align-items-center">
          <input type="text" class="form-control mr-2" v-model="keyword">
          <button class="btn btn-secondary">Search</button>
        </div>
      </form>
      <em v-if="state != 'loading'">
        {{ totalItems | currency('', 0) }}
        {{ totalItems > 1 ? 'records' : 'record' }}
      </em>
    </div>

    <table-records v-if="records.length > 0" :records="records" :ignore-fields="[]"></table-records>

    <div v-if="hasFilters && records.length == 0" class="alert alert-warning">
      No records found
    </div>

    <div class="d-flex justify-content-between">
      <div class="actions d-flex">
        <beat-loader v-if="state == 'loading'"></beat-loader>
      </div>
      <global-pagination :total-pages="totalPages" :current-page="currentPage"
        @goto-page="doPaging"></global-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BeatLoader from 'vue-spinner/src/BeatLoader'
import ServiceRedbook from '@/services/redbook'
import TableRecords from './TableRecords'

export default {
  components: {
    BeatLoader,
    TableRecords,
  },
  data () {
    return {
      state: 'loading',
      keyword: '',
      totalItems: 0,
      currentPage: 1,
      totalPages: 0,
      records: [],
    }
  },
  computed: mapState({
    hasFilters () {
      let self = this;
      return self.keyword != '';
    },
  }),
  mounted () {
    this.init();
  },
  methods: {
    init () {
      let self = this;
      self.getRecords();
    },
    doViewAll () {
      let self = this;
      self.currentPage = 1;
      self.keyword = '';
      self.getRecords();
    },
    doPaging ( page ) {
      let self = this;
      self.currentPage = page;
      self.getRecords();
    },
    getRecords () {
      let self = this,
        params = {
          page: self.currentPage,
        };

      if ( self.keyword !== '' ) {
        params.kw = self.keyword;
      }

      self.state = 'loading';
      ServiceRedbook.getOptEquips(params, resp => {
        self.state = 'loaded';
        self.records = resp.data;
        self.totalPages = resp.last_page;
        self.totalItems = resp.total;
      });
    }
  }
}
</script>
