import 'font-awesome/css/font-awesome.css'
import { forEach } from 'lodash'
import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import './axios'
import './validator'
import '@/mixins'

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VuejsDialog from 'vuejs-dialog'
Vue.use(VuejsDialog);

import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true });

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#25daa5',
  failedColor: '#f05528',
  thickness: '4px',
  autoFinish: false
});

Vue.config.productionTip = false

import Pagination from '@/components/global/Pagination'
Vue.component('global-pagination', Pagination);

new Vue({
  router: Router,
  store: Store,
  created () {
    let self = this;

    self.axios.interceptors.request.use(config => {
      self.$Progress.start();

      const accessToken = self.$localStorage.get('access_token');
      if ( accessToken ) {
        config.headers.common['Authorization'] = 'Bearer ' + accessToken;
      }

      return config;
    }, error => {
      self.$Progress.fail();
      return Promise.reject(error);
    });

    self.axios.interceptors.response.use(response => {
      self.$Progress.finish();
      return response;
    }, error => {
      let errors = [], msgs = '';

      self.$Progress.fail();

      if ( !error.response ) {
        self.$notify({
          type: 'error',
          title: 'Invalid Request',
          text: 'Sorry, we could not locate the resource you are trying to get.',
        });
        return Promise.reject(error);
      }

      switch (error.response.status) {
        case 401:
          // self.logout();
          // self.$localStorage.remove( 'access_token' );
          self.$router.push({ name: 'login' });
          break;
        case 422:
          errors = error.response.data.errors;
          msgs = [];

          forEach(errors, (val) => {
            msgs.push( val[0] );
          });

          self.$notify({
            type: 'error',
            title: 'Form Validation Failed',
            text: msgs.join('<br>'),
          });
          break;
        case 403:
          self.$notify({
            type: 'error',
            title: 'Permission Denined',
            text: 'Sorry, you are not allow to perform this action.',
          });
          // self.$router.push({ name: 'not-found' });
          break;
        case 500:
          self.$notify({
            type: 'error',
            title: 'Internal Server Error',
            text: 'Please reload the application and try again.',
          });
          break;
        case 404:
          self.$notify({
            type: 'error',
            title: 'Invalid Request',
            text: 'Sorry, we could not locate the resource you are trying to get.',
          });
          break;
      }
      return Promise.reject(error);
    });
  },
  render: h => h(App),
}).$mount('#app')
