import Vue from 'vue'
import VueRouter from 'vue-router'

import Posts from '@/routers/posts'
import Pages from '@/routers/pages'
import Brand from '@/routers/brand'
import Redbook from '@/routers/redbook'
import Aftermarket from '@/routers/aftermarket'
import Settings from '@/routers/settings'
import Users from '@/routers/users'
import Account from '@/routers/account'
import DefaultRoutes from '@/routers/default'

Vue.use(VueRouter);

export default new VueRouter({
  routes: [].concat(
    Posts,
    Pages,
    Brand,
    Redbook,
    Aftermarket,
    Settings,
    Users,
    Account,
    DefaultRoutes
  )
});
