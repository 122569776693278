<template>
  <div class="card border-light">
    <div class="card-body">
      <div class="loader-overlay" v-if="state == 'requesting'">
        <moon-loader :color="'#28a745'"></moon-loader>
      </div>

      <h5 class="card-title border-bottom pb-2">Reset password</h5>

      <div class="alert alert-success" v-if="state == 'forgot-success'">
        {{ successMsg }}
      </div>

      <div class="alert alert-danger" v-if="state == 'forgot-failed'">
        {{ errorMsg }}
      </div>

      <form method="POST" @submit.prevent="doSendLink">
        <div class="form-group">
          <router-link tabindex="4" class="float-right" :to="{name: 'login'}">Login instead</router-link>

          <label for="forgotEmail">Email address</label>
          <input type="email" tabindex="1" name="email" id="forgotEmail" ref="inputEmail" autofocus="on"
            :class="{'form-control': true, 'is-invalid': errors.has('email')}"
            v-validate.disable="{ required: true, email: true }"
            v-model="email">
          <div class="invalid-feedback">{{ errors.first('email') }}</div>
        </div>

        <button class="btn btn-primary" tabindex="3">Send Reset Password Link</button>
      </form>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader'
import ServiceAuth from '@/services/auth'

export default {
  name: 'AuthForgot',
  components: {
    MoonLoader,
  },
  data () {
    return {
      state: '',
      email: '',
      password: '',
      errorMsg: '',
      successMsg: '',
    };
  },
  computed: {},
  mounted () {
  },
  methods: {
    doSendLink () {
      let self = this;

      if (self.state == 'requesting') {
        return;
      }

      self.$validator.validateAll()
        .then(ok => {
          if ( ok ) {
            self.state = 'requesting';

            ServiceAuth.forgot(self.email, resp => {
              if ( resp.status == 'SUCCESS' ) {
                self.state = 'forgot-success';
                self.successMsg = resp.message;
                self.email = '';
              }
              else {
                self.state = 'forgot-failed';
                self.errorMsg = resp.error;
              }
            });
          }
        });
    }
  }
}
</script>
