<template>
  <div class="brand-pages">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h2 mb-0"><i class="fa fa-file"></i> Brand Pages</span>
      <button type="button" class="btn btn-sm btn-success" @click="showModalAdd = true">
        <i class="fa fa-plus"></i> New page</button>
    </div>

    <modal-add-brand-page v-model="showModalAdd"
      @brand-page-added="onAdded"></modal-add-brand-page>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ServiceBrandPage from '@/services/brandpage'
import ModalAddBrandPage from './ModalAddBrandPage'
// import ModalEditUserType from './ModalEditUserType'

export default {
  name: 'BrandIndex',
  components: {
    ModalAddBrandPage,
  },
  data () {
    return {
      showModalAdd: false,
    }
  },
  computed: mapState({

  }),
  mounted () {},
  methods: {
    init () {
    },
    onAdded () {

    },
    getPages (callback) {
      let self = this,
        params = {
        };

      self.state = 'loading';
      ServiceBrandPage.getBrandPages(params, resp => {
        self.state = 'loaded';
        self.pages = resp.data;

        if ( typeof callback=='function' ) {
          callback()
        }
      });
    }
  }
}
</script>
