import Axios from 'axios'

export default {
  getBrandPages ( params, callback ) {
    Axios.get('/brand-pages', { params })
      .then(resp => {
        callback( resp.data );
      })
      .catch(() => {});
  },
  create ( data, callback, fallback ) {
    Axios.post( '/brand-pages', data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback(error);
      });
  },
  save ( itemId, data, callback, fallback ) {
    Axios.post( '/brand-pages/' + itemId, data )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
  delete ( itemId, callback, fallback ) {
    Axios.delete( '/brand-pages/' + itemId )
      .then(resp => {
        callback( resp.data );
      })
      .catch(error => {
        fallback( error );
      });
  },
}
