<template>
  <div class="table-responsive mb-3">
    <table class="table table-sm table-striped table-bordered mb-0">
      <thead class="thead-light">
        <tr>
          <th v-for="(recordKey, index) in recordKeys" :key="index">{{ recordKey }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, index) in records" :key="index">
          <td v-for="(recordKey, index) in recordKeys" :key="index">
            {{ record[recordKey] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { filter, keys, indexOf } from 'lodash'

export default {
  data () {
    return {
    };
  },
  props: ['records', 'ignoreFields'],
  computed: {
    recordKeys () {
      let self = this, recordKeys = [];
      if ( self.records.length > 0 ) {
        recordKeys = filter(keys( self.records[0] ), key => {
          return indexOf( self.ignoreFields, key ) < 0;
        });
      }
      return recordKeys;
    },
  },
  methods: {
  }
}
</script>
